<script>
import APIStore from '@app/services/API/Store';

export default {
  head: {
    title: 'Facturation - Paramètres',
  },
  data() {
    return {
      invoices: null,
    };
  },
  mounted() {
    this.url = this.$env.apiResourcesURL;
    this.storeUUID = this.$store.getters['auth/store'].uuid;

    APIStore.getInvoices(this.storeUUID)
      .then((data) => (this.invoices = data));
  },
  methods: {
    download(row) {
      const loader = this.$buefy.loading.open({ container: this.$refs.list });
      APIStore.getInvoice(this.storeUUID, row.id)
        .then((invoice) => (Object.assign(row, invoice.data)))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Factures
      </h2>
      <p>
        <strong>Besoin d'un justificatif ?</strong>
        Retrouvez ici toutes vos factures Teachizy.
      </p>
      <p>
        Vous utilisez le pack DÉCOUVERTE ?
        Nous vous adressons une facture tous les mois correspondant aux commissions
        que nous aurons perçues sur les règlements de vos apprenants.
      </p>
      <p>
        Vous utilisez le pack PRO ou le pack EXPERT ?
        Vous n'avez pas de commission dans ce cas, mais nous vous adressons
        une facture mensuelle ou annuelle correspondant à vos frais d'abonnement à Teachizy.
      </p>
    </div>

    <div ref="list" class="column is-8 is-relative">
      <div class="box">
        <p v-if="invoices && invoices.data.length < 1">
          Aucune facture pour le moment.
        </p>
        <b-table v-else-if="invoices && invoices.data.length > 0" :data="invoices.data" scrollable>
          <b-table-column v-slot="{ row }" field="created_at" label="Date">
            {{ row.created_at | momentFromUTC | moment('DD/MM/YY') }}
          </b-table-column>
          <b-table-column v-slot="{ row }" field="number" label="Référence">
            #{{ row.number }}
          </b-table-column>
          <b-table-column v-slot="{ row }" field="amount" label="Montant" centered>
            {{ row.amount }} &euro;
          </b-table-column>
          <b-table-column v-slot="{ row }" field="status" label="Payée" centered>
            <span class="tag valign-middle" :class="row.status === 'PAID' ? 'is-primary': 'is-danger'">
              {{ row.status === 'PAID' ? 'Oui' : 'Non' }}
            </span>
          </b-table-column>
          <b-table-column v-slot="{ row }">
            <a
              v-if="row.path"
              class="is-size-7 is-text"
              :href="`${url}/${row.path}`"
              target="_blank"
            >
              Voir
            </a>
            <a v-else class="is-size-7 is-text" @click.prevent="download(row)">
              Télécharger
            </a>
          </b-table-column>
        </b-table>
        <div v-else>
          <b-skeleton height="32" animated />
          <b-skeleton height="32" animated />
          <b-skeleton height="32" animated />
          <b-skeleton height="32" animated />
        </div>
      </div>
    </div>
  </div>
</template>
